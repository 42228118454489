import React from 'react'
import { Formik, Form } from 'formik'
import { navigateTo } from 'gatsby-link'
import PropTypes from 'prop-types'

import TextInput from '../UI/Inputs/TextInput'
import EmailInput from '../UI/Inputs/EmailInput'
import TelInput from '../UI/Inputs/TelInput'
import TextArea from '../UI/Inputs/TextArea'
import { LocaleContext } from '../Layout'

import * as S from './styled'

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

const ContactForm = () => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const isPt = lang.locale === 'pt-pt'

  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', email: '', contact: '', message: '' }}
      validate={values => {
        const errors = {}
        const { required, emailInvalid } = i18n.formGeneral.errors
        if (!values.email) {
          errors.email = required
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = emailInvalid
        }
        if (!values.firstName) {
          errors.firstName = required
        }
        if (!values.lastName) {
          errors.lastName = required
        }
        if (!values.contact) {
          errors.contact = required
        }
        if (!values.message) {
          errors.message = required
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'general', ...values }),
        })
          .then(() => navigateTo('/success'))
          .catch(() => (isPt ? navigateTo('error') : navigateTo('en/error')))

        setSubmitting(false)
      }}
    >
      {({ isSubmitting, errors, ...props }) => (
        <Form name="general" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <S.FormWrapper>
            <S.FormRow>
              <input type="hidden" name="form-name" value="general" />
              <S.FormCol>
                <TextInput
                  label={`${i18n.formGeneral.firstName.label}*`}
                  placeholder={i18n.formGeneral.firstName.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.firstName}
                  name="firstName"
                  errors={!!(errors.firstName && errors.firstName.length !== 0)}
                  errorMessage={errors.firstName}
                />
              </S.FormCol>
              <S.FormCol>
                <TextInput
                  label={`${i18n.formGeneral.lastName.label}*`}
                  placeholder={i18n.formGeneral.lastName.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.lastName}
                  name="lastName"
                  errors={!!(errors.lastName && errors.lastName.length !== 0)}
                  errorMessage={errors.lastName}
                />
              </S.FormCol>
            </S.FormRow>
            <S.FormRow>
              <S.FormCol>
                <EmailInput
                  label={`${i18n.formGeneral.email.label}*`}
                  placeholder={i18n.formGeneral.email.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                  name="email"
                  errors={!!(errors.email && errors.email.length !== 0)}
                  errorMessage={errors.email}
                />
              </S.FormCol>
              <S.FormCol>
                <TelInput
                  label={`${i18n.formGeneral.contact.label}*`}
                  placeholder={i18n.formGeneral.contact.placeholder}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.contact}
                  name="contact"
                  errors={!!(errors.contact && errors.contact.length !== 0)}
                  errorMessage={errors.contact}
                />
              </S.FormCol>
            </S.FormRow>
            <S.Row>
              <TextArea
                label={`${i18n.formGeneral.message.label}*`}
                placeholder={i18n.formGeneral.message.placeholder}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.message}
                name="message"
                errors={!!(errors.message && errors.message.length !== 0)}
                errorMessage={errors.message}
              />
            </S.Row>
            <S.Button type="submit" disabled={isSubmitting}>
              {i18n.formGeneral.submit}
            </S.Button>
          </S.FormWrapper>
        </Form>
      )}
    </Formik>
  )
}

ContactForm.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    contact: PropTypes.string,
    message: PropTypes.string,
  }),
}

ContactForm.defaultProps = {
  handleChange: () => {},
  handleBlur: () => {},
  values: {},
}
export default ContactForm
