import styled from '@emotion/styled'
import bubbles from '../../../content/images/bubbles.svg'
import { Button as DefaultButton } from '../UI/Buttons'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-bottom: 32px;
  }
`

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  background: ${({ theme }) => theme.colors.bg};
  box-shadow: -1px 25px 20px 0 #f2f2f2;
  border-radius: 4px;
  padding: 76px 16px;
`

export const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  :before {
    content: '';
    display: inline-block;
    background-image: url(${bubbles});
    background-size: contain;
    height: 33px;
    width: 43px;
    margin-right: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 1.5rem;
  }
`

export const Button = styled(DefaultButton)`
  font-size: 1rem;
  max-width: 246px;
  padding: 4px 26px;
  margin-top: 24px;
`
