import React from 'react'
import { Link } from 'gatsby'

import { LocaleContext } from '../Layout'
import * as S from './styled'

const Reservation = () => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const isPt = lang.locale === 'pt-pt'
  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Title>{i18n.reservation}</S.Title>
        <Link to={isPt ? '/reservation' : '/en/reservation'}>
          <S.Button>{i18n.buttonPost}</S.Button>
        </Link>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}
export default Reservation
