import styled from '@emotion/styled'
import { Row as DefaultRow, Col as DefaultCol } from '../UI/Grid'
import { Button as DefaultButton } from '../UI/Buttons'

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding-top: 32px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0;
  }
`
export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 16px;
  width: 100%;
  flex-direction: column;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 48px 16px;
  }
`

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  max-width: 400px;
  padding-bottom: 180px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 16px;
  }
`
export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;
  max-width: 200px;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Text = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2rem;

  padding: 32px 0;
`

export const Col = styled(DefaultCol)``
export const FormCol = styled(DefaultCol)`
  width: calc(50% - 16px);
`
export const MainCol = styled(DefaultCol)`
  width: 50%;
`

export const Row = styled(DefaultRow)`
  z-index: 0;
  padding-top: 32px;
`
export const FormRow = styled(DefaultRow)`
  justify-content: space-between;
  z-index: 0;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: -72px;
  max-width: 400px;
  max-height: 427px;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }
`

export const Bubbles = styled.img`
  position: relative;
  max-width: 156px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-left: 16px;
    max-width: 100px;
  }
`

export const Label = styled.p`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 18px;
  padding-bottom: 12px;
`

export const AddressText = styled.p`
  font-size: 1rem;
  letter-spacing: -0.2px;
  line-height: 22px;
  padding-bottom: 24px;
`

export const Button = styled(DefaultButton)`
  max-width: 200px;
  margin-top: 24px;
  justify-content: center;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 100%;
  }
`
