import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'
import ContactForm from './ContactForm'
import background from '../../../content/images/half-size-cat.png'
import bubbles from '../../../content/images/bubbles.svg'
import { LocaleContext } from '../Layout'

const ContactsPage = ({ data }) => {
  const { hero_title: title, email, address, mobile } = data
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <S.Wrapper>
      <S.Image src={background} />
      <S.TopWrapper>
        <S.Title>{title}</S.Title>
        <S.Bubbles src={bubbles} alt="Contacts" />
      </S.TopWrapper>
      <S.Row>
        <S.MainCol>
          <S.Row>
            <S.Col>
              <S.Label>{i18n.address}</S.Label>
              <S.AddressText dangerouslySetInnerHTML={{ __html: address.html }} />
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>Email</S.Label>
              <S.AddressText>{email}</S.AddressText>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Col>
              <S.Label>{i18n.contactUs}</S.Label>
              <S.AddressText>{mobile}</S.AddressText>
            </S.Col>
          </S.Row>
        </S.MainCol>
        <S.MainCol>
          <ContactForm />
        </S.MainCol>
      </S.Row>
    </S.Wrapper>
  )
}

ContactsPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default ContactsPage
