import styled from '@emotion/styled'

export const ErrorWrapper = styled.div`
  position: relative;
  padding-bottom: 16px;
  margin-top: -10px;
`
export const Error = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
`
