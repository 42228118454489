import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import ContactsPage from '../components/ContactsPage'
import Reservation from '../components/Reservation'
import i18n from '../../config/i18n'

const Contacts = ({ data: { contacts }, pageContext: { locale }, location }) => {
  const { data } = contacts

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} title={i18n[locale].seo.contactUs.title} />
      <ContactsPage data={data} />
      <Reservation />
    </>
  )
}

export default Contacts

Contacts.propTypes = {
  data: PropTypes.shape({
    contacts: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query contactsQuery($locale: String!) {
    contacts: prismicContactsPage(lang: { eq: $locale }) {
      data {
        hero_title
        address {
          html
        }
        email
        mobile
      }
    }
  }
`
