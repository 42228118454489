import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Input, { Label } from './index'
import { ErrorWrapper, Error } from '../Errors'

const TelInput = ({
  name,
  label,
  placeholder,
  onChange,
  onBlur,
  errors,
  errorMessage,
  defaultValue,
  maxLength,
  ...props
}) => (
  <Fragment>
    <Label>{label}</Label>
    <Input
      type="tel"
      name={name}
      value={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      errors={errors}
      maxLength={maxLength}
      {...props}
    />
    {errors && (
      <ErrorWrapper>
        <Error>{errorMessage}</Error>
      </ErrorWrapper>
    )}
  </Fragment>
)

TelInput.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  errors: PropTypes.bool,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.string,
}

TelInput.defaultProps = {
  errors: false,
  defaultValue: '',
  errorMessage: '',
  onBlur: () => {},
  maxLength: '',
}

export default TelInput
