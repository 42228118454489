import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Field } from 'formik'

const hasErrors = ({ theme, errors }) =>
  css`
    position: relative;
    color: ${errors ? theme.colors.red : theme.colors.grey};
    border-color: ${errors ? theme.colors.red : theme.colors.greyLighter};
  `
const Input = styled(Field)`
  /* Input box: */
  display: inline-flex;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 2px;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.greyDark};
  text-indent: 10px;
  font-size: 1rem;
  line-height: 24px;

  padding: 12px 0;

  background: ${({ theme }) => theme.colors.white};

  letter-spacing: -0.24px;
  vertical-align: middle;

  -webkit-appearance: none;

  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out};

  &:disabled {
    color: ${({ theme }) => theme.colors.grey};
    border-color: ${({ theme }) => theme.colors.greyLight};
    cursor: not-allowed;

    &:hover {
      border-color: ${({ theme }) => theme.colors.greyLight};
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyDark};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey};
  }

  &:active,
  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.orange};
  }

  & + &,
  & + select,
  & + input {
    border-left: none;
  }

  ${hasErrors}
`

export default Input

export const InputWithPadding = styled(Input)`
  padding-right: 41px;
`

export const IconHolder = styled.div`
  position: relative;
`

export const InputTextArea = styled.textarea`
  /* Input box: */
  display: inline-flex;
  width: 100%;
  height: 180px;
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors.greyLighter};
  border-radius: 2px;
  text-indent: 10px;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.greyDark};

  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  padding: 12px 0;

  background: ${({ theme }) => theme.colors.white};

  letter-spacing: -0.24px;
  line-height: 24px;
  vertical-align: middle;

  -webkit-appearance: none;

  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out};

  &:disabled {
    color: ${({ theme }) => theme.colors.grey};
    border-color: ${({ theme }) => theme.colors.greyLight};
    cursor: not-allowed;

    &:hover {
      border-color: ${({ theme }) => theme.colors.greyLight};
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyDark};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey};
  }

  &:active,
  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.orange};
  }

  & + &,
  & + select,
  & + input {
    border-left: none;
  }

  ${hasErrors}
`

export const Label = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 0.2px;
  line-height: 18px;
  padding-bottom: 8px;
`
